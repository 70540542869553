import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ChatGPTLogo from "../../images/svg-icons/OpenAI.svg"
import HindustanTimesSVG from "../../images/Hindustan_Times_logo.svg"
import MintSVG from "../../images/Mint_logo.svg"
import SeoLondonSVG from "../../images/seo-london.svg"
import SignupForm from "../SignupForm";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Hr = () => (
  <hr
    style={{
      margin: "5rem",
    }}
  />
);

const StyledH2 = styled.h2`
  font-size: 1.5rem;
  /* font-weight: 800; */
  margin-bottom: 2rem;
  @media only screen and (min-width: 700px) {
    font-size: 2.5rem;
  }
`;

const HomeFeatures = () => {

  return (
    // <div className="marketing pb-5">
    <Container className="pb-5">
      <Hr />
      <Motivation />
      <Hr />
      <TheyTrustUs />
      <Hr />
      <TeamManagement />
      <SignupForm />
    </Container>
    // </div>
  );
};

export default HomeFeatures;


const TheyTrustUs = () => (
  <Row className="trust">
    <div >
      <div className="text-center pb-2">
        <StyledH2>We empower Top Publishers and SEO Agencies</StyledH2>
        {/* <p style={{ fontSize: "1.5rem" }}>They protect large sites from plagiarism<br />with unprecedented levels of productivity.</p> */}
      </div >
      <div className="container text-center align-bottom mt-5">
        <Row>
          <Col className="col-12 col-sm-4 my-3">
            <HindustanTimesSVG style={{ maxHeight: '50' }} />
          </Col>
          <Col className="col-12 col-sm-4 my-3">
            <MintSVG style={{ maxHeight: '50' }} />
            {/* style={{ margin: "1rem", width: '200' }} /> */}
          </Col>
          <Col className="col-12 col-sm-4 my-3">
            <SeoLondonSVG style={{ maxHeight: '50' }} />
            {/* style={{ margin: "1rem", width: '200' }} /> */}
          </Col>
        </Row>
      </div>
    </div>

  </Row>
)

const Motivation = () => (
  <Row className="motivation">
    <div className="col-md-8">
      <StyledH2>
        Use ChatGPT<br />
        With peace of mind
      </StyledH2>
      <div style={{ fontSize: "1.5rem" }}>
        <p>
          ChatGPT might produce sentences very close to existing content.
          <br />
          Identify them and find the potential sources <br />
          to either quote them or rework the text.
        </p>
      </div>
    </div>
    <div className="col-md-4">
      <ChatGPTLogo style={{ margin: "1rem" }} />
    </div>
  </Row>
);

const TeamManagement = () => (
  <Row className="Findcopycats">
    <div className="col">
      <div className="text-center">
        <StyledH2>
          Unlimited checks
          <br /> For your entire team
        </StyledH2>
        <p style={{ fontSize: "1.5rem" }}>
          Invite team members and <br />
          allow them to perform unlimited plagiarism ChatGPT checks too.
        </p>
      </div>
    </div>
  </Row>
);