import React from "react";
import { TitleXL, TitleCutout } from "../Typography";
// import JoinBeta from "../JoinBeta";
import SignupForm from "../SignupForm";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
// import HeroSvg from './digital-agency-hero.svg'
import heroImg from '../../images/jpg/chatgpt-plagiarism-checker.jpg'

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";


import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const HeroSection = ({ data }) => {
  // const HeroImage = () => <Img fluid={data.filename.childImageSharp.fluid} />;
  return (
    <Container
      fluid={true}
      className="HeroSection pb-5"
    >
      <Row style={{ textAlign: "center" }}
        className="mt-5 mb-5"
      // style={{ maxHeight: "70%" }}
      >
        <Col xs={12} md={6} style={{ textAlign: "center" }}>
          <TitleXL>
            {/* Scale your customers' content<br />
          Get them the best <TitleCutout>ROI</TitleCutout> */}
            {/* Get the most out of<br />
          your articles */}
            ChatGPT<br />
            Plagiarism Checker
          </TitleXL>
          <p style={{ fontSize: "1.5rem" }}>
            Ensure the output of ChatGPT is unique<br />
            with our Chrome Extension</p>
          <Button className="font-weight-bold m-2" size="lg" href="https://app.plagiashield.com/app/signup"
            target="_blank"
            rel="noopener noreferrer"
          >
            Get unlimited checks
          </Button>
          {/* <p className="font-weight-light" style={{ fontSize: '0.8rem' }}>7-day free trial. $3 per month</p> */}
          {/* <Button className="font-weight-bold m-2" variant="outline-primary" size="lg" href="https://plagiashield.user.com/calendar/meeting/jeremy/#/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Get a demo
          </Button> */}

        </Col>
        <Col xs={12} md={6} style={{ alignItems: "center", justifyContent: "center" }}>
          <img className="img-fluid rounded" src={heroImg} alt="ChatGPT plagiarism checker" />

          {/* <HeroImage /> */}
          {/* <HeroSvg /> */}
        </Col>
      </Row>
      {/* <h1 class="display-1">Your Content Has Been Stolen</h1> */}
      {/* <JoinBeta /> */}

      {/* <SignupForm /> */}
      {/* <p>No Credit Card Required.</p> */}
    </Container>
  );
};



export default HeroSection;

// export const query = graphql`
//   query {
//     filename: file(relativePath: { eq: "example-results.png" }) {
//       childImageSharp {
//         fluid(maxWidth: 800) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `;
// const CustomForm = () => (
//   <Form>
//     <Form.Group controlId="formGroupEmail">
//       <Form.Label>Email address</Form.Label>
//       <Form.Control
//         type="email"
//         placeholder="Business Email Address"
//         aria-label="Recipient's username"
//         aria-describedby="basic-addon2"
//       />
//     </Form.Group>
//     <Button variant="primary" type="submit">
//       Submit
//     </Button>
//   </Form>
// );
