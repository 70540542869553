import React from "react";
import loadable from "@loadable/component";
import Seo from "../components/Seo";
import Container from "react-bootstrap/Container";
import HeroSectionChatGPT from '../components/HeroSectionChatGPT'
import ChatGPTFeatures from '../components/ChatGPTFeatures'

const IndexPage = () => (
  <React.Fragment>
    <Seo manualTitle="ChatGPT Plagiarism Checker - Ensure Originality of Your Content" />
    <Container>
      <HeroSectionChatGPT />
      <ChatGPTFeatures />
    </Container>
    {/* <HeroSection /> */}
    {/* <HomeFeatures /> */}
  </React.Fragment>
);

export default IndexPage;
